import { Grid } from "@mui/material";
import { ReactComponent as HeroImg } from "../../../assets/launch.svg";
import Content from "../../../components/Text/Content";
import Heading from "../../../components/Text/Heading";
import {
  heroContent,
  heroHeading,
} from "./styles";
import { useWindowSize } from "@uidotdev/usehooks";

const heroTranslations = { title: 'Accelerate Your Software Development', description: 'We design and develop mobile and web apps that delight your users and drive business growth. We combine enterprise-grade development with outstanding design to deliver exceptional results.' };

const deriveFontSize = (size: number | null) => {
  if (!size || size < 500) {
    return 30;
  }

  return 50;
}

const Hero = () => {
  const { width } = useWindowSize();

  const fontSize = deriveFontSize(width);

  return (
    <Grid sx={{ background: "#E2ECEC", px: "6.25rem" }}>
      <Grid container item sm>
        <Heading sx={{ ...heroHeading, fontSize, }}>{heroTranslations.title}</Heading>
        <Content sx={heroContent}>
          {heroTranslations.description}
        </Content>
      </Grid>
      <Grid container item sm>
        <HeroImg />
      </Grid>
    </Grid>
  );
};

export default Hero;
