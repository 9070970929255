import { Grid } from "@mui/material";
import Heading from "../../Text/Heading";
import { contactInfoText } from "./styles";
import { ContactType } from "./types";
import { useWindowSize } from "@uidotdev/usehooks";

const deriveFontSize = (width: number | null) => {
  if (!width) {
    return "1.313rem";
  }

  if (width < 500) {
    return "0.9rem";
  }

  return "1.313rem";
}

const Contact = ({ icon, contactInfo }: ContactType) => {
  const { width } = useWindowSize();

  const fontSize = deriveFontSize(width);

  return (
    <Grid container gap={1} mb="1.813rem" alignItems="center">
      <Grid item>
        <img src={icon} alt="contact" style={{ marginTop: ".4rem" }} />
      </Grid>
      <Grid item>
        <Heading sx={{ ...contactInfoText, fontSize }}>{contactInfo}</Heading>
      </Grid>
    </Grid>
  );
};

export default Contact;
