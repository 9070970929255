import uiuxIcon from '../../../assets/uiux.svg';
import designIcon from '../../../assets/design.svg';
import frontEndIcon from '../../../assets/frontend.svg';
import backendIcon from '../../../assets/backend.svg';

export const information = [
  {
    icon: uiuxIcon,
    title: "UI/UX",
    content:
      "Our UI/UX philosophy is simple: if you have to explain your app to a user, it's not designed well enough. We prioritize intuitive and user-friendly interfaces that require no explanation.",
  },
  {
    icon: designIcon,
    title: "Design Systems",
    content:
      "We don't just develop individual apps; we create comprehensive design systems. Starting with your first app, we establish a cohesive design system that streamlines development for all your future applications, ensuring consistency and efficiency.",
  },
  {
    icon: frontEndIcon,
    title: "App Dev",
    content:
      "Whether you need a mobile or web app, we write code to the highest standards. Our expertise lies in React, React Native, and Flutter, allowing us to build robust and performant applications.",
  },
  {
    icon: backendIcon,
    title: "Backend",
    content:
      "We develop high-quality, flexible, and scalable API and backend systems. We understand that your core software engine is critical to your enterprise, and we treat its development with the utmost priority and care.",
  },
];
