export const footerWrapper = { background: "#F6F9FC" };

export const footerHeading = {
  fontWeight: 700,
  color: '#03103B',
};

export const divider = { margin: "5.5rem 0 2.813rem" };

export const footerLink = {
  fontSize: 15,
  cursor: "pointer",
  ":hover": { opacity: ".5", transition: ".3s" },
  color: "#03103B",
};
