export const cardTitle = {
  margin: "1.875rem 0 1.25rem",
  fontWeight: 700,
  fontSize: 21,
  lineHeight: "1.5rem",
  color: "#EEE2DC",
};

export const cardContent = {
  lineHeight: "1.625rem",
  color: "#EEE2DC",
  opacity: ".7",
};
