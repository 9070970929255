import { Box, Divider, Grid } from "@mui/material";
import Content from "../Text/Content";
import Heading from "../Text/Heading";
import { contactInformation, footerLinks } from "./constants";
import Contact from "./Contact";
import { divider, footerHeading, footerLink, footerWrapper } from "./styles";
import { useWindowSize } from "@uidotdev/usehooks";

const deriveFontSize = (width: number | null) => {
  if (!width) {
    return "2.3rem";
  }

  if (width < 500) {
    return "1.5rem";
  }

  return "2.3rem";
}

const Footer = () => {
  const { width } = useWindowSize();
  const fontSize = deriveFontSize(width);

  return (
    <Box padding="5rem 7rem 2.5rem" sx={footerWrapper}>
      <Heading sx={{ ...footerHeading, fontSize, }}>
        Connect with us now!
      </Heading>
      {contactInformation.map((contact, index) => (
        <Contact
          key={`contact-info-item-footer-${index}`}
          icon={contact.icon}
          contactInfo={contact.contactInfo}
        />
      ))}
      <Divider sx={divider} />
      <Grid container>
        <Grid item>
          <Content sx={footerLink}>
            © GetSetIO LLC 2022, All Rights Reserved
          </Content>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
