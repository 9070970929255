import { Button, Grid } from "@mui/material";
import logo from "../../assets/getsetio-logo.png";
import Content from "../Text/Content";
import { navBarLinks } from "./constants";
import {
  getYourCardButton,
  navLinkItem,
  signInButton,
  signInText,
} from "./styles";
import { useWindowSize } from "@uidotdev/usehooks";

const deriveLogoSize = (width: number | null) => {
  if (!width) {
    return 300;
  }

  if (width < 500) {
    return 200;
  }

  return 300;
}

const Navbar = () => {
  const { width } = useWindowSize();

  const iconWidth = deriveLogoSize(width);

  return (
    <Grid
      container
      gap={8}
      alignItems="center"
      px="2rem"
      paddingTop={"2rem"}
      sx={{ background: "#E2ECEC" }}
    >
      <Grid item>
        <img src={logo} width={iconWidth} alt="logo" />
      </Grid>
      <Grid item sm>
        <Grid container alignItems="center" gap={6}>
        </Grid>
      </Grid>
      {/* <Grid item>
        <Button sx={getYourCardButton}>
          <Content sx={{ fontWeight: 500 }}>Contact us</Content>
        </Button>
      </Grid> */}
    </Grid>
  );
};

export default Navbar;
